<template>
  <div class="reporting-page">
    <div class="container page">
      <v-data-table
        :headers="headers"
        :items="allreportings"
        sort-by="createdat"
        class="table-bordered"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Reporting</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
          </v-toolbar>
        </template>
        <template v-slot:[`item.createdat`]="{ item }">
          <span>{{ new Date(item.createdat).toLocaleString() }}</span>
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="initialize"> Reset </v-btn>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { mapState } from "vuex";
import { REPORTING_FETCH } from "@/store/actions.type";

export default {
  name: "reporting",
  data: () => ({
    headers: [
      {
        text: "id",
        align: "id",
        value: "id",
      },
      { text: "Campingplatz", value: "campingPlace.shortname" },
      { text: "Generierter User", value: "username" },
      { text: "gültige Tage", value: "expirydays" },
      { text: "Erstellt am", value: "createdat" },
    ],
    allreportings: [],
    editedIndex: -1,
    editedItem: {
      id: "",
      campname: "",
      username: "",
      expirydays: "",
      createdat: "",
    },
    defaultItem: {
      id: "",
      campname: "",
      username: "",
      expirydays: "",
      createdat: "",
    },
  }),
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.$store.dispatch(REPORTING_FETCH).then(() => {
        this.allreportings = this.getReportings;
      });
    },
  },
  computed: {
    ...mapGetters(["getReportings"]),
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
  },
};
</script>
